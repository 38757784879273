import React, { FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { ICustomTabsComponentProps, ICustomTabsItem } from './model';
import 'components/common/CustomTabs/CustomTabs.scss';

const CustomTabs: FC<ICustomTabsComponentProps> = ({ items, selectedIndex, onSelectCallback }) => (
  <Tabs selectedIndex={selectedIndex} onSelect={onSelectCallback}>
    <TabList>
      {items.map((item: ICustomTabsItem) => (
        <Tab key={item.tabTitle.name} className={['react-tabs__tab', item.className]} tabIndex="-1">
          <button className="tab-opener" type="button" aria-label={item.tabTitle.ariaLabel}>
            {item.tabTitle.name}
          </button>
        </Tab>
      ))}
    </TabList>
    {items.map((item: ICustomTabsItem) => (
      <TabPanel key={item.tabTitle.name}>{item.content}</TabPanel>
    ))}
  </Tabs>
);

export default CustomTabs;
